.status-image{
    &-wrapper {
        border: 2px solid #3d692f;
        width: 48em;
        height: 48em;
        max-height: calc(100% - 2em);
        max-width: calc(100% - 2em);
        display: flex;
    }

    &-inner-wrapper {
        border: 3px solid #64c040;
        height: 100%;
        width: 100%;
        background-color: #30343e;
        color: white;
        padding: 2.25em;
        box-sizing: border-box;
    }

    &-content {
        display: flex;
        justify-content: space-between;
    }
}

.primary-container, .secondary-container {
    flex: 0 0 calc(100% / 2 - 0.75em);
    width: 100%;
    max-width: calc(100% / 2 - 0.75em);
}

.logo-wrapper {
    width: 100%;
    max-width: 10em;
    margin-bottom: 1em;
}

.logo {
    width: 100%;
    height: 100%;
}

.separator {
    width: 100%;
    border-bottom: 1px solid hsla(0, 0%, 100%, 25%);
}

.field {
    background-color: transparent;
    border: none;
    outline: none;
    color: white;
    font-size: 3em;
    margin-bottom: 0.5em;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.field-span {
    font-size: 3em;
    margin-bottom: 0.5em;
    display: inline-block;
    padding: 1px 2px;
}

h3 {
    line-height: 1.4;
    margin-top: 1.5em;
}

.members-list {
    list-style: none;
    padding-left: 0;
    margin: 3em 0;
}

.list-item {
    position: relative;
    padding-left: 2.25em;
    font-size: 1.125em;
    margin-bottom: 1.75em;
    color: hsla(0, 0%, 100%, 80%);

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 2em;
        height: 1em;
        background-image: url("../../Images/hand-right.png");
        background-repeat: no-repeat;
    }

    &:focus {
        border: none;
        outline: none;
    }
}

.date-info {
    margin-top: 2em;
    color: hsla(0, 0%, 100%, 50%);
    font-size: 0.625em;
    text-align: right;

    span {
        font-weight: bold;
    }
}

.listField {
    margin-bottom: 1em;
    border: none;
    background: none;
    color: #fff;
    border-bottom: 1px solid hsla(0, 0%, 100%, 50%);
    padding-bottom: 1em;

    &:focus {
        border-bottom: 1px solid hsla(0, 0%, 100%, 50%);
        outline: none;
    }
}

.button {
    padding: 0.75em 1.25em;
    outline: none;
    border: none;
    cursor: pointer;
    border: 1px solid #64c040;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}